<template>
	<div class="app-container">
		<formItem
			class="queryForm"
			style="margin: 10px 0"
			ref="formItem"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<el-row style="margin: 0 20px" v-if="showTree">
			<el-col :span="12" v-for="(item, inx) in treeDataList" :key="inx">
				<div>
					<div style="margin-bottom: 20px">{{ item.title }}</div>
					<el-tree
						:data="item.treeData"
						:show-checkbox="showCheckBox"
						node-key="id"
						:default-checked-keys="checkListData[item.dataKey]"
						:props="item.props || {}"
						default-expand-all
						@check="item.check"
					>
					</el-tree>
				</div>
			</el-col>
		</el-row>
		<empty v-else />
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
// import { Loading } from 'element-ui';
import empty from '@/views/components/components-empty/index.vue';
import { transfromTreeData } from '@/utils/util';
export default {
	name: 'marketInsightindustry',
	components: { formItem, empty },

	data() {
		return {
			visible: true,
			dialogVisible: false,
			dialogModel: {},
			formItems: [
				{
					label: '客户名称',
					labelWidth: '100px',
					key: 'comId',
					type: 'selectSearch',
					firstRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/own/company/list', { request: { pageNo: 1, pageSize: 20, comName: query, companyStatus: '0' } })
							.then((res) => res.list?.map(({ id, comName }) => ({ dictId: id, dictName: comName }))),
					span: 6,
					change: () => {
						this.getPermissionList();
					}
				},
				// {
				// 	span: 3,
				// 	labelWidth: '0px',
				// 	style: 'min-width: 100px',
				// 	render: () => (
				// 		<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.getPermissionList}>
				// 			查询
				// 		</el-button>
				// 	)
				// },
				{
					span: 18,
					labelWidth: '0px',
					style: 'text-align: right;min-width: 310px',
					render: () => (
						<div style="min-width: 310px">
							{this.showCheckBox ? (
								<el-button size="small" type="primary" style="margin-top: 5px" onclick={this.submitCheck}>
									提交
								</el-button>
							) : (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-edit"
									onclick={() => {
										this.showCheckBox = true;
									}}
								>
									编辑
								</el-button>
							)}
						</div>
					)
				}
			],
			formModel: {},
			columns: [],
			treeDataList: [],
			showCheckBox: false,
			checkListData: {},
			showTree: false,
			allData: {}
		};
	},
	watch: {},
	computed: {},
	async mounted() {
		this.getTreeData();
	},

	methods: {
		getTreeData() {
			this.$axios.post('/v1/menu/listAll', { request: {} }).then((res) => {
				this.treeDataList = [
					{
						title: '管理菜单配置',
						treeData: res.adminMenuList,
						props: { label: 'menuName' },
						dataKey: 'adminMenuIds',
						check: (e, { checkedKeys }) => {
							this.checkListData.adminMenuIds = [...checkedKeys];
						}
					},
					{
						title: '个人菜单配置',
						treeData: res.staffMenuList,
						props: { label: 'menuName' },
						dataKey: 'staffMenuIds',
						check: (e, { checkedKeys }) => {
							this.checkListData.staffMenuIds = [...checkedKeys];
						}
					}
				];
			});
		},
		getPermissionList() {
			this.showTree = false;
			this.$axios.post('/v1/menu/findMenuIdsComId', { request: { comId: this.formModel.comId } }).then((res) => {
				if (res?.code) {
					return;
				}
				this.showTree = true;
				this.allData = res;
				this.checkListData = res;
			});
		},
		getAllParentIds(ids, list) {
			let allId = [];

			ids.forEach((i) => {
				const item = list.find(({ id }) => id === i);

				if (item?.parentId && item?.parentId !== '0') {
					allId = allId.concat([item.parentId, ...this.getAllParentIds([item.parentId], list)]);
				}
			});
			return allId;
		},
		submitCheck() {
			if (!this.formModel.comId) {
				return this.$message.warning('请选择客户');
			}

			const { adminMenuIds, staffMenuIds } = this.checkListData;
			const allTreeList = transfromTreeData(this.treeDataList[0]?.treeData);
			const staffAllTreeList = transfromTreeData(this.treeDataList[1]?.treeData);

			const adminHalfIds = this.getAllParentIds(adminMenuIds, allTreeList);

			const staffhalfIds = this.getAllParentIds(staffMenuIds, staffAllTreeList);

			let [adminCheckIds, staffCheckIds] = [[], []];

			if (adminMenuIds?.length) {
				adminCheckIds = Array.from(new Set([...adminMenuIds, ...adminHalfIds]));
			}
			if (staffMenuIds?.length) {
				staffCheckIds = Array.from(new Set([...staffMenuIds, ...staffhalfIds]));
			}

			this.$axios
				.post('/v1/menu/addCompanyMenu', {
					request: {
						menuIds: [...adminCheckIds, ...staffCheckIds],
						comId: this.formModel.comId
					}
				})
				.then((res) => {
					if (res?.code) {
						return;
					}
					this.showCheckBox = false;
					// this.checkListData = {};
					this.$message.success('编辑成功');
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.app-container {
	// padding: 30px 20px !impo rtant;
	.table_item {
		// height: 600px;
		width: 100%;
		// margin: 20px 0;
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_wrap {
		background: #fff;

		.table_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: fixed;
			}
		}
		.view_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.tag-title {
		height: 30px;
		line-height: 30px;
	}
	.color-item {
		height: 30px;
		ul {
			display: inline-flex;
			width: 100%;
			height: 100%;
			li {
				margin-right: 20px;
				margin-bottom: 10px;
				width: 20px;
				border-radius: 50%;
				cursor: pointer;
				opacity: 0.6;
			}
		}
	}
	.tag-item {
		cursor: pointer;
		border: none;
	}
	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
</style>
<style lang="scss">
.queryForm {
	.formStyle {
		margin-bottom: 12px;
	}
	.el-form-item {
		margin-bottom: 10px;
	}
}
.table_wrap {
	.table_item {
		.el-table__row .el-table__cell .cell {
			height: 100%;
			.mergeDiv {
				display: flex;
				justify-content: space-between;
				min-height: 20px;
				height: 100%;
				align-items: center;
			}
		}
	}
}
</style>
