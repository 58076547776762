<template>
	<div class="container">
		<baseTable
			class="baseTableItem"
			ref="baseTableItem"
			@request="getTableData"
			:queryItem="queryItem"
			rowKey="id"
			:columns="tableCols"
			:otherBtns="otherBtns"
			:showPagination="false"
			@cellMouseEnter="mouseEnterDebounce"
			@cellMouseLeave="cellMouseLeave"
		/>
		<el-dialog
			width="900px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="editForm"
				:formItems="dialogItems"
				@submit="this.submitForm"
				@cancel="dialogVisible = false"
				:defaultData="formModel"
			/>
		</el-dialog>
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import { Loading } from 'element-ui';
import { debounce } from 'loadsh';
// import moment from 'moment';
export default {
	name: 'customerModuleConfig',
	components: {
		baseTable,
		formItem
	},

	props: { activeName: { type: String } },
	computed: {},
	data() {
		return {
			tableData: [],
			tableCols: [
				{
					label: '菜单名称',
					key: 'menuName',
					width: '350px',
					align: 'left',
					render: (row) => {
						const showOper = row.component === this.hoverRow.component && row.menuName === this.hoverRow.menuName;
						const notShow = row.isShow === 0;
						const opSh = showOper ? '' : 'none';

						return (
							<div style="display:inline-flex">
								<span style={{ color: notShow ? 'rgba(0,0,0,0.3)' : '#333' }}>{row.menuName} </span>
								<span style={{ marginLeft: '20px', color: '#0066ff', cursor: 'pointer', display: opSh }}>
									<i class="el-icon-plus" style="margin-right: 6px" onClick={() => this.handleAdd(row)} title="新增"></i>
									<i class="el-icon-edit" style="margin-right: 6px" onClick={() => this.handleEdit(row)} title="编辑"></i>
									<i
										class="el-icon-delete"
										style="margin-right: 4px;color:#f56c6c"
										onClick={() => this.handleDel(row)}
										title="删除"
									></i>
								</span>
							</div>
						);
					}
				},
				{ label: '是否显示', key: 'isShow', formatter: (v) => (v === 1 ? '是' : '否') },
				{ label: '路由地址', key: 'path', width: '200px' },
				{
					label: '菜单状态',
					key: 'status',
					formatter: (v) => this.statusOpts.find(({ dictId }) => dictId === v)?.dictName
				},
				// { label: '菜单图标', key: 'icon' },
				{ label: '菜单类型', key: 'menuType', formatter: (v) => this.menusTypeOpts.find(({ dictId }) => dictId === v)?.dictName }, // （M目录 C菜单 F按钮）
				{ label: '组件', key: 'component' }, // （M目录 C菜单 F tab）
				{ label: '模块类型', key: 'moduleType', formatter: (v) => this.mouduleTypeOpts.find(({ dictId }) => Number(dictId) === v)?.dictName }, // 1管理员，2员工
				{ label: '备注', key: 'remark' }
			],

			queryItem: [
				// {
				// 	label: '菜单名称',
				// 	key: 'menuName',
				// 	type: 'input',
				// 	span: 5,
				// 	change: (e) => {
				// 		console.log(e, 'eeeeeeeeeeeeeeeee');
				// 	}
				// }
				// { label: '修改时间', key: 'updateTime', type: 'date', span: 5 }
			],
			dialogItems: [
				{
					label: '菜单类型',
					key: 'menuType',
					type: 'radio',
					span: 12,
					options: (e, formData) => {
						if (formData.disabled) {
							return this.menusTypeOpts.map((val) => ({ ...val, disabled: Boolean(formData.menuType) }));
						}
						return this.menusTypeOpts.map((val) => {
							if (val.dictId === 'F') {
								val.disabled = true;
							}
							return val;
						});
					},
					rules: [{ required: true, message: '请选择', trigger: 'change' }]
				},
				{ label: '菜单名称', key: 'menuName', type: 'input', span: 12, rules: [{ required: true, message: '请输入', trigger: 'blur' }] },
				{ label: '序号', key: 'orderNum', type: 'input', span: 12 },
				{
					label: '路由地址',
					key: 'path',
					type: 'input',
					span: 12,
					placeholder: '请输入路由地址',
					// disabled: (e) => {
					// 	if (e.menuType === 'F') {
					// 		return true;
					// 	}
					// },
					rules: (e) => {
						if (e.menuType === 'C') {
							return [{ required: true, message: '请输入', trigger: 'blur' }];
						}
					}
				},
				// {
				// 	label: '菜单状态',
				// 	key: 'status',
				// 	type: 'radio',
				// 	span: 12,
				// 	options: () => this.statusOpts
				// },
				// { label: '菜单图标', key: 'icon', type: 'input', span: 12, placeholder: '请输入地址' },

				{ label: '组件', key: 'component', type: 'input', span: 12 },
				// { label: '模块类型', key: 'moduleType', type: 'radio', span: 12, options: () => this.mouduleTypeOpts },
				{ label: '备注', key: 'remark', type: 'input', span: 12 },
				{
					label: '是否显示(在菜单)',
					key: 'isShow',
					type: 'radio',
					span: 12,
					options: [
						{ dictId: 1, dictName: '是' },
						{ dictId: 0, dictName: '否' }
					]
				}
			],
			otherBtns: [
				{
					label: '添加新菜单',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.handleAdd();
					}
				}
			],
			dialogVisible: false,
			formModel: {},
			dialogTitle: '添加',
			hoverRow: {},
			statusOpts: [
				{ dictName: '正常', dictId: '0' },
				{ dictName: '停用', dictId: '1' }
			],
			menusTypeOpts: [
				{ dictName: '目录', dictId: 'M' },
				{ dictName: '菜单', dictId: 'C' },
				{ dictName: '标签页', dictId: 'F' }
			],
			mouduleTypeOpts: [
				{ dictName: '管理员', dictId: '1' },
				{ dictName: '员工', dictId: '2' }
			],
			mouseEnterDebounce: () => {}
		};
	},
	created() {},
	mounted() {
		this.mouseEnterDebounce = debounce(this.cellMouseEnter, 100);
	},
	methods: {
		getTableData(params, callback) {
			callback(
				this.$axios.post('/v1/menu/listAll', { request: { moduleType: this.activeName } }).then((res) => {
					const { adminMenuList, staffMenuList } = res;
					const arr = this.activeName === '1' ? adminMenuList : staffMenuList;

					return { list: arr };
				})
			);
		},
		handleAdd(row) {
			this.dialogTitle = '添加';
			if (row) {
				const menuType = ['C', 'F'].includes(row.menuType) ? 'F' : '';

				this.formModel = {
					parentId: row.id,
					topId: row.topId,
					menuType,
					path: row.path,
					disabled: ['C', 'F'].includes(row.menuType),
					isShow: 1
				};
			} else {
				this.formModel = { isShow: 1 };
			}
			// this.form?.resetFields();
			if (this.$refs?.formItem?.form) {
				this.$refs?.formItem.form.resetFields();
			}
			this.dialogVisible = true;
		},
		cellMouseEnter(e) {
			this.hoverRow = e[0];
		},
		cellMouseLeave() {
			this.hoverRow = {};
		},
		// 编辑
		handleEdit(row) {
			this.dialogTitle = '编辑';
			this.formModel = { ...row, disabled: true };
			this.dialogVisible = true;
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				const loading = Loading.service({ target: '.baseTableItem' });

				this.$axios
					.post('/v1/menu/deleteById', {
						request: { id: row.id }
					})
					.then((res) => {
						loading.close();
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTableItem?.handleSearch();
						}
					});
			});
		},
		// 新增提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					const loading = Loading.service({ target: '.editForm' });

					return this.$axios
						.post('/v1/menu/save', { request: { ...this.formModel, moduleType: this.activeName } })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTableItem?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped></style>
