<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="管理菜单权限管理" name="1">
				<menuConfig :activeName="activeName" v-if="activeName === '1'" />
			</el-tab-pane>
			<el-tab-pane label="个人菜单权限管理" name="2">
				<menuConfig :activeName="activeName" v-if="activeName === '2'" />
			</el-tab-pane>
			<el-tab-pane label="菜单权限设置" name="3">
				<menuSetting :activeName="activeName" v-if="activeName === '3'" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import menuConfig from '@/views/system-config/platformSystemConfig/menuConfig';
import menuSetting from '@/views/system-config/platformSystemConfig/menuSetting';

export default {
	components: {
		menuConfig,
		menuSetting
	},
	/**  mounted*/
	mounted() {},
	/**  data*/
	data() {
		return {
			activeName: '1'
		};
	},
	methods: {}
};
</script>

<style></style>
